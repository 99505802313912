import { render, staticRenderFns } from "./Pouso-Redondo.vue?vue&type=template&id=a9506ca4&scoped=true&"
import script from "./Pouso-Redondo.vue?vue&type=script&lang=js&"
export * from "./Pouso-Redondo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9506ca4",
  null
  
)

export default component.exports